@import "@/styles/var.scss";
@import "@/styles/mixin.scss";

.fullPage {
  height: 100%;
  line-height: normal;
  background-color: #f5f6f9;

  .main {
    padding: 20px 16px 10px;
    background-repeat: no-repeat;
    background-size: 100% 432px;
    background-image: url("~@imgs/ios-skin/head-bg.jpg");

    .logoBox {
      font-size: 20px;
      font-weight: 600;
      line-height: 20px;

      @include flex-cc;

      .logo {
        margin-right: 10px;
        width: 49px;
        height: 49px;
        background-size: 100%;
        background-image: url("~@imgs/register/red-theme/logo-big.png");
      }

      .logoBoxRight {
        display: flex;
        flex-direction: column;
        .logoText {
          width: 80px;
          height: 21px
        }
        .youqianLogoText {
          width: 54px;
          height: 21px
        }
        .logoText2 {
          margin-top: 4px;
          width: 79px;
          height: 11px
        }
        .logoText3 {
          margin-top: 1px;
          font-size: 12px;
          color: #fff;
          font-weight: 600;
          transform: scale(.86) translateX(-10px);
        }
      }
    }

    .applyResultBox {
      margin-top: 15px;
      background: linear-gradient(314deg, #FFFAF9 0%, #FFEEE6 100%);
      border-radius: 7px;
      border: 2px solid #DD2231;
      display: flex;
      align-items: center;
      padding: 9px;
      .applyResultBoxIcon {
        width: 60px;
        height: 60px;
        flex-shrink: 0;
        margin-right: 8px;
      }
      .applyResultBoxRight {
        .applyResultBoxText1 {
          font-size: 16px;
          font-weight: bold;
          color: #000;
          .active {
            color: #F80109;
          }
        }
        .applyResultBoxText2 {
          margin-top: 4px;
          font-size: 14px;
          color: #967569;
          .highlight {
            color: #F80109;
          }
          &.smallText {
            font-size: 12px;
          }
        }
      }
    }

    .containers {
      margin-top: 13px;
      border-radius: 14px;
      background-color: #fff;
      position: relative;
      &.passBox {
        height: 460px;
        padding: 36px;
      }
      &.failBox {
        height: 460px;
        .containersBg {
          width: 100%;
        }
        .textBox {
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;
          text-align: center;
          padding: 64px 0 0;
          .textBoxTitle {
            font-size: 14px;
            font-weight: bold;
          }
          .textBoxPrice {
            margin-top: 10px;
            font-size: 39px;
            font-weight: bold;
          }
        }
      }
      .line {
        width: 1px;
        height: 35px;
        margin-left: 7px;
      }
      .containerItem {
        display: flex;
        align-items: center;
        .applyResultBoxIcon {
          width: 17px;
          height: 17px;
          margin-right: 24px;
        }
        .containerItemSuccessText {
          color: #FF2E43;
          font-size: 18px;
          font-weight: bold;
        }
        .containerItemFailText {
          color: #939393;
          font-size: 18px;
        }
      }
    }
  }

  .btnBox {
    width: 340px;
    padding: 0 16px;
    margin-top: 150px;
    position: fixed;
    bottom: 150px;
    left: 50%;
    transform: translateX(-50%);
    .downLoadTips {
      font-size: 16px;
      color: #555555;
      margin-bottom: 20px;
      text-align: center;
    }
    .btn {
      border: none;
      padding: 0;
      height: 48px;
      background: $linear-gradient;
      line-height: 50px;
      font-size: 16px;
    }
  }
}
