@import "@/styles/var.scss";
@import "@/styles/mixin.scss";

.failure {
  text-align: center;

  .failWarnIcon {
    margin-top: 20vh;
    font-size: 60px;
    color: #FA8C16;
  }

  .text {
    margin-top: 20px;
    font-size: 24px;
    line-height: 34px;
    color: $text-gray-color;
  }
}
