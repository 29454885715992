@import "@/styles/var.scss";
@import "@/styles/mixin.scss";

.authorizePage {
  padding-top: 48px;
  line-height: normal;
  background-color: #fff;
  min-height: 100vh;
  position: relative;
  .rematchGif {
    width: 250px;
    height: 250px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
  }
}
