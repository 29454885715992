:root:root {
  --adm-color-primary: #298AF5;
  --adm-color-black: #262626;

  //   --adm-color-success: #00B578;
  //   --adm-color-warning: #FF8F1F;
  //   --adm-color-danger: #FF3141;
  //   --adm-color-white: #FFFFFF;
  //   --adm-color-text: #333333;
  //   --adm-color-text-secondary: #666666;
  //   --adm-color-weak: #999999;
  //   --adm-color-light: #CCCCCC;
  //   --adm-color-border: #EEEEEE;
  //   --adm-color-box: #F5F5F5;
  //   --adm-color-background: #FFFFFF;
  //   --adm-font-size-main: var(--adm-font-size-5);
  //   --adm-font-family:
  //     -apple-system,
  //     blinkmacsystemfont,
  //     "Helvetica Neue",
  //     helvetica,
  //     segoe ui,
  //     arial,
  //     roboto,
  //     "PingFang SC",
  //     "miui",
  //     "Hiragino Sans GB",
  //     "Microsoft Yahei",
  //     sans-serif;
}
