@import "@/styles/var.scss";
@import "@/styles/mixin.scss";

.registerPage {
  height: 100%;
  background-color: #F6F6F6;

  #root{
    height: 100%;
  }

  #loading-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    flex-flow: column;
    align-items: center;
  }

  @-webkit-keyframes loadingRotate {
    0% {
      transform: rotate(0deg) scale(1);
    }

    50% {
      transform: rotate(180deg) scale(0.6);
    }

    100% {
      transform: rotate(360deg) scale(1);
    }
  }

  @keyframes loadingRotate {
    0% {
      transform: rotate(0deg) scale(1);
    }

    50% {
      transform: rotate(180deg) scale(0.6);
    }

    100% {
      transform: rotate(360deg) scale(1);
    }
  }

  .ballClipRotateMultiple {
    position: relative;
    width: 50px;
    height: 50px;
  }

  .ballClipRotateMultiple>div {
    position: absolute;
    left: 0px;
    top: 0px;
    border: 2.5px solid #007AEA;
    border-bottom-color: #0000;
    border-top-color: #0000;
    border-radius: 100%;
    height: 35px;
    width: 35px;
    -webkit-animation: loadingRotate 1s 0s ease-in-out infinite;
    animation: loadingRotate 1s 0s ease-in-out infinite;
  }

  .ballClipRotateMultiple>div:last-child {
    display: inline-block;
    top: 10px;
    left: 10px;
    width: 15px;
    height: 15px;
    -webkit-animation-duration: .5s;
    animation-duration: .5s;
    border-color: #007AEA #0000;
    -webkit-animation-direction: reverse;
    animation-direction: reverse;
  }

  #loading-box .dot {
    display: inline-block;
    border-radius: 50%;
    width: 2px;
    height: 2px;
    animation: dotting .8s infinite step-start;
  }

  @keyframes dotting {
    25% {
      box-shadow: 2px 0 0 #007AEA;
    }

    50% {
      box-shadow: 2px 0 0 #007AEA, 6px 0 0 #007AEA;
    }

    75% {
      box-shadow: 2px 0 0 #007AEA, 6px 0 #007AEA, 10px 0 0 #007AEA;
    }
  }

  @-webkit-keyframes dotting {
    25% {
      box-shadow: 2px 0 0 #007AEA;
    }

    50% {
      box-shadow: 2px 0 0 #007AEA, 6px 0 0 #007AEA;
    }

    75% {
      box-shadow: 2px 0 0 #007AEA, 6px 0 #007AEA, 10px 0 0 #007AEA;
    }
  }
}
