@import "@/styles/var.scss";
@import "@/styles/mixin.scss";

.authorizePage {
  padding-top: 48px;
  line-height: normal;
  background-color: #fff;
  min-height: 100vh;
  .headerBox {
    margin-top: 8px;
    .progress1 {
      width: 141px;
      display: block;
      margin: 0 auto;
    }
  }
  .waitingText1 {
    margin-top: 6px;
    color: #262626;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
  }
  .waitingText2 {
    margin-top: 8px;
    color: #8C8C8C;
    text-align: center;
    font-size: 15px;
  }

  .progressBar {
    padding: 0 36px;
    .progress2 {
      width: 100%;
    }
  }
}
