@import "@/styles/var.scss";
@import "@/styles/mixin.scss";

.modalPage {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  background-color: rgba($color: #000000, $alpha: 80%);

  .main {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY((-50%));
    width: 320px;
    height: 411px;
    border-radius: 8px;
    background: linear-gradient(180deg, #FFEDED 0%, #FFF9F9 60.54%);
    padding: 20px;
    box-sizing: border-box;
    .title {
      color: #E14E5B;
      font-size: 20px;
      font-weight: 600;
    }
    .subTitle {
      margin-top: 6px;
      color: #262626;
      font-size: 14px;
    }
    .modalIcon1 {
      width: 96px;
      height: 96px;
      position: absolute;
      right: -20px;
      top: -20px;
    }
    .contentBox {
      margin: 12px auto;
      width: 100%;
      height: 170px;
      border-radius: 8px;
      background: #FFF;
      box-shadow: 0px 4px 30px 0px rgba(153, 153, 153, 0.10);
      padding: 0 12px;
      box-sizing: border-box;
      .productNameBar {
        padding: 12px 0;
        display: flex;
        align-items: center;
        color: #50515C;
        font-size: 15px;
        font-weight: 600;
        border-bottom: 1px solid #ddd;
        .productIcon {
          width: 20px;
          height: 20px;
          margin-right: 8px;
        }
      }
      .contentItemBar {
        padding: 12px 0;
        border-bottom: 1px solid #ddd;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .contentItemLable {
          font-size: 12px;
          color: #262626;
        }
        .contentItemValue {
          font-size: 15px;
          color: #262626;
          font-weight: 600;
        }
      }
    }
    .textTips {
      margin: 15px 0;
      color: #262626;
      font-size: 12px;
      text-align: center;
    }
    .btnBox {
      margin-top: 16px;
      .btn {
        border: none;
        padding: 0;
        height: 48px;
        background: $linear-gradient;
        line-height: 50px;
      }
    }
    .protocolIdsBox {
      text-align: center;
      margin-top: 15px;
      font-size: 12px;
      color: #E14E5B;
      display: flex;
      justify-content: center;
    }
  }
}
