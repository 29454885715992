@import "@/styles/var.scss";
@import "@/styles/mixin.scss";

.fullPage {
  padding-top: 48px;
  min-height: 100vh;
  background-color: #F6F8FB;
  .authorizeInfo {
    margin: 20px auto 32px;
    padding: 20px 33px;
    width: 346px;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 11px;
    .authorizeCompany {
      margin-top: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      .authorizeCompanyItem {
        .authorizeCompanyLogo {
          width: 65px;
          height: 65px;
        }
        .authorizeCompanyName {
          margin-top: 12px;
          font-size: 16px;
          font-weight: bold;
          color: #424242;
          text-align: center;
        }
      }
      .interconnection {
        margin: -20px 27px 0;
        width: 23px;
        height: 25px;
      }
    }
    .authorizeRemark {
      margin-top: 34px;
      padding: 22px 0;
      font-size: 13px;
      color: #999999;
      border-top: 1px solid #DDDDDD;
    }
    .authorizeInfoItem {
      display: flex;
      align-items: center;
      .authorizeInfoItemSort {
        margin-right: 11px;
        width: 5px;
        height: 5px;
        background-color: #81A8FF;
        border-radius: 50%;
      }
      .authorizeInfoItemText {
        font-size: 13px;
        color: #81A8FF;
      }
    }
  }
  .agreementBox {
    padding-top: 18px;
    margin-top: 16px;
    font-size: 12px;
    color: #A7A7A7;
    border-top: 1px solid #DDDDDD;
    display: flex;
    .agreementItem {
      color: #81A8FF;
    }
    .checked {
       width: 15px;
       height: 15px;
       margin-right: 10px;
       flex-shrink: 0;
       img {
        width: 100%;
       }
    }
  }
  .btn {
    margin: 0 auto;
    border: none;
    padding: 0;
    width: 280px;
    height: 48px;
    font-size: 16px;
    font-weight: bold;
    background: linear-gradient(180deg, #72AEFF 0%, #0C4CFF 100%);
    line-height: 48px;
  }
}
