@import "@/styles/var.scss";
@import "@/styles/ant-theme.scss";

.app {
  align-items: center;
  overflow: auto;
  height: 100%;
  color: $color-black;
  background: $background-color;
}
