@import "@/styles/var.scss";
@import "@/styles/mixin.scss";

.svgIcon {
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1em;
  vertical-align: -.15em;
}

.svgIconInner {
  overflow: hidden;
  width: 100%;
  height: 100%;
  fill: currentcolor;
  stroke: none;
}
