@import "@/styles/var.scss";
@import "@/styles/mixin.scss";

.privacyAgreement {
  overflow: auto;
  padding: 16px;
  height: 100%;
  font-size: 12px;
  color: $color-black;
  background-color: $color-white;

  &.isShowHeader {
    padding-top: 64px;
  }

  .title {
    margin-bottom: 10px;
    font-size: 18px;
    text-align: center;
  }

  .subTitle {
    margin: 8px 0;
    font-size: 14px;
  }

  p {
    text-align: justify;
    text-indent: 2em;
    line-height: 20px;
  }

  .bold {
    font-weight: bold;
  }

  .indent4 {
    text-indent: 4em;
  }

  .underline {
    text-decoration: underline;
  }

  .emptyLine {
    height: 20px;
  }

  table {
    margin: 10px 0;
    border: 1px solid #D9D9D9;
    border-collapse: collapse;

    tr {
      border: 1px solid #D9D9D9;

      td {
        border: 1px solid #D9D9D9;

        &:first-child,
        &:last-child {
          width: 70px;
          text-align: center;
        }
      }
    }
  }
}
