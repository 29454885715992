@import "@/styles/var.scss";
@import "@/styles/mixin.scss";

.login {
  min-height: 100%;
  background-color: $color-white;

  .bold {
    font-weight: bold;
    color: $color-primary;
  }

  .header {
    position: relative;
    overflow: hidden;
    height: 220px;
    color: $color-white;
    background-size: cover;
    background-image: url("~@imgs/login/top-bg.png");
    flex-flow: column;

    @include flex-cc;

    /* 三角形 */
    &::after {
      position: absolute;
      content: "";
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      border: 8px solid $color-white;
      border-color: transparent transparent  $color-white transparent;
    }

    .tip {
      font-size: 32px;
      line-height: 45px;
    }

    .title {
      font-size: 44px;
      font-weight: 600;
      line-height: 62px;
    }
  }

  // 表单样式修改
  .form {
    --border-inner: none;
    --border-bottom: none;
    --border-top: none;

    padding: 30px 30px 0;

    .row {
      position: relative;

      .codeBox {
        position: absolute;
        top: 23px;
        right: 0;
        border: none;
        border-radius: 36px;
        padding: 0;
        width: 94px;
        height: 36px;
        font-size: 14px;
        font-weight: bold;
        color: $color-white;
        background: $linear-gradient;

        @include flex-cc;
      }

      :global {
        .adm-button-disabled {
          color: $placeholder-color;
          background: $background-color;
          opacity: 1;
        }
      }
    }

    :global {
      .adm-list-item-content-main {
        padding: 16px 0 0;
      }

      .adm-button:active::before {
        border-radius: 36px;
        opacity: .08;
      }

      .adm-form-footer {
        padding: 40px 0 0;
      }

      .adm-list-item-title {
        padding-left: 10px;
      }

      .adm-form-item-label {
        padding-left: 2px;
        font-size: 13px;
        color: $text-gray-color;
      }

      .adm-list-item {
        padding-left: 0;

        .adm-list-item-content {
          padding-right: 0;
        }
      }

      // input组件样式修改
      .adm-input {
        border-bottom: 1px solid $line-color;
        height: 50px;
        line-height: 50px;

        .adm-input-element {
          font-size: 14px;

          &::placeholder {
            font-size: 14px;
            color: $placeholder-color;
          }
        }
      }

      .adm-form-item {
        &-has-error {
          .adm-input {
            border-color: red;
          }
        }
      }
    }
  }

  .btnBox {
    display: flex;
    justify-content: center;

    .btn {
      border: none;
      border-radius: 80px;
      padding: 0;
      width: 100%;
      height: 48px;
      background: $linear-gradient;
      line-height: 48px;
    }
  }

  .agreement {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    font-size: 12px;
    color: #888888;

    .checked {
      margin-right: 6px;
      border: 1px solid $border-color;
      border-radius: 100%;
      width: 18px;
      height: 18px;

      &.active {
        position: relative;
        border: none;
        background: $linear-gradient;

        &::before {
          position: absolute;
          top: 6px;
          left: 4px;
          width: 10px;
          height: 7px;
          content: "";
          background-image: url("~@imgs/activity/check.png");
          background-size: 100%;
        }
      }
    }
  }
}
